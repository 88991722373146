.teaser-grid {
    $this: &;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;

    @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__item {
        cursor: pointer;
        position: relative;
        display: grid;
        grid-column: span 1;
        background-color: var(--bg-light);
        text-decoration: none;

        &--big {
            grid-column: span 2;

            @include media-breakpoint-down(md) {
                grid-column: span 1;
            }
        }

        &:hover {
            #{$this}__image {
                img {
                    scale: 1.15;
                }

            }
        }

        @media (hover: none) {
            #{$this}__image {
                img {
                    scale: 1;
                }

            }
        }
    }

    &__image {
        position: relative;
        grid-column: 1/-1;
        grid-row: 1/-1;
        aspect-ratio: 3/2;
        width: 100%;
        padding: 0;
        margin: 0;
        z-index: 5;
        overflow: clip;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     inset: 0;
        //     background-color: rgba($tertiary, .4);
        //     z-index: 5;
        //     @include effect(all, .5s, ease-in-out);
        // }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            @include effect(scale, .5s);
        }
    }

    // &__box {
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: flex-end;
    //     padding: 2rem calc(50px + 3rem) 2rem 2rem;
    //     height: 100%;
    //     z-index: 10;
    // }

    // &__btn {
    //     position: absolute;
    //     inset: auto 2rem 2rem auto;
    //     display: inline-flex;
    //     justify-content: center;
    //     align-items: center;
    //     width: 50px;
    //     height: 50px;
    //     background-color: var(--btn-primary);
    //     border-radius: 50vw;
    //     z-index: 15;
    //     @include effect();

    //     &::after {
    //         content: "";
    //         position: relative;
    //         width: 20px;
    //         height: 20px;
    //         background-color: white;
    //         mask-image: url('../img/icon/icon-arrow-right.svg');
    //         mask-repeat: no-repeat;
    //         mask-position: center;
    //         mask-size: 20px;
    //     }

    //     &:hover {
    //         background-color: var(--btn-primary-hover);
    //     }
    // }


    &__title {
        display: grid;
        align-items: flex-end;
        position: relative;
        grid-column: 1/-1;
        grid-row: 1/-1;
        font-size: 24px;
        --title: white;
        padding: 24px;
        margin: 0;
        z-index: 20;
        text-shadow: 0 4px 6px rgba(0, 0, 0, .6), 0 2px 4px rgba(0, 0, 0, .4);
    }

    // &__text {
    //     --text: white;
    //     font-size: 16px;
    // }

}