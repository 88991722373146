:root {
	--section-pt: 150px;
	--container-gap: 40px;
}

main {
	display: block; //IE9-11 Support
}

.section {
	padding-block: var(--section-pt);

	@include media-breakpoint-down(xl) {
		--section-pt: 100px;
	}

	@include media-breakpoint-down(sm) {
		--section-pt: 50px;
	}

	&--first {
		@include media-breakpoint-down(md) {
			--section-pt: 150px;
		}

		@include media-breakpoint-down(sm) {
			--section-pt: 150px;
		}
	}

	&[data-bg="dark"] {
		background-color: var(--bg-dark);
	}

	&[data-bg="light"] {
		background-color: var(--bg-light);
	}

	&[data-size="min"] {
		min-height: 100vh;
	}

	&[data-section="story"] {
		position: relative;
		padding-block: inherit;
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		.box {
			align-self: center;
			position: relative;
			margin-block-start: -100vh;
			margin-inline: auto;
			padding-inline: 5rem;
			padding-block: 25vh;
			width: 100%;
			max-width: 800px;
			z-index: 10;

			&[data-position='bottom'] {
				align-self: flex-end;
				padding-block: 0 3rem;
			}

			&--right {
				grid-column: 2/-1;
			}

			@include media-breakpoint-down(xl) {
				padding-inline: inherit;
				grid-column: span 2;
			}
		}

		.image {
			grid-column: 1 / -1;
			position: sticky;
			top: 0;
			height: 100vh;
			z-index: 5;
			overflow: hidden;
			//&::after {
			//	content: "";
			//	position: absolute;
			//	inset: 0;
			//	background-color: rgba(black, 0.75);
			//}

			img {
				object-fit: cover;
				object-position: center;
				height: 100%;
				width: 100%;
			}
		}

	}

	&[data-section="full"] {
		position: relative;
		display: grid;
		padding-block: initial;
		min-height: 100vh;

		@include media-breakpoint-down(md) {
			min-height: initial;
		}

		.image {
			position: absolute;
			inset: 0;
			overflow: hidden;
			z-index: 0;

			@include media-breakpoint-down(md) {
				position: relative;
				inset: inherit;
				aspect-ratio: 5/3;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.box {
			align-self: center;
			padding-block: 100px;
			width: 100%;
			z-index: 10;

			@include media-breakpoint-down(md) {
				padding-block: 0;
			}

			&[data-position="start"] {
				align-self: flex-start;
			}
			
			&[data-position="end"] {
				align-self: flex-end;
			}
		}

		@include media-breakpoint-down(md) {
			.container {
				padding-right: 0;
				padding-left: 0;
			}
		}

		.content-box {
			padding: 3rem;
			background-color: white;

			@include media-breakpoint-down(md) {
				padding-inline: 0;
			}

			&[data-bg="dark"] {
				background-color: var(--bg-dark);
			}
		
			&[data-bg="light"] {
				background-color: var(--bg-light);
			}

			@include media-breakpoint-down(md) {
				padding-right: var(--container-gap);
				padding-left: var(--container-gap);
			}
		}
	}

	&[data-section="detail"] {
		position: relative;
		padding-block-start: inherit;

		.image {
			position: relative;
			margin-inline: auto;
			// max-width: 1920px;
			aspect-ratio: 2/1;

			&::after {
				content: "";
				position: absolute;
				inset: auto 0 0;
				width: 100vw;
				height: clamp(50px, 15vw, 150px);
				// background-color: rgb(38, 50, 56);
				background: linear-gradient(180deg, rgba(var(--bg-gradient-rgb), 0.9) 0%, rgba(var(--bg-gradient-rgb), 1) 90%);
			}

			img {
				object-fit: cover;
				object-position: center top;
				height: 100%;
				width: 100%;
			}
		}
	}

	&[data-section="half"] {
		position: relative;
		padding-block: inherit;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		min-height: 100vh;

		@include media-breakpoint-down(xl) {
			min-height: 75vh;
		}

		@include media-breakpoint-down(md) {
			grid-template-columns: repeat(1, 1fr);
			min-height: inherit;
		}

		.image {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			@include media-breakpoint-down(md) {
				width: 100%;
				aspect-ratio: 1/1;
				order: 1;
			}

			img {
				object-fit: cover;
				object-position: center top;
				height: 100%;
				width: 100%;
			}
		}

		.box {
			margin-inline: auto;
			padding-block: 2rem;
			max-width: 640px;
			align-self: center;

			@include media-breakpoint-down(md) {
				order: 2;
				padding-block-end: 4rem;
			}
		}
	}
}

.section-item {
	&:not(:last-child) {
		margin-bottom: 4rem;

		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
	}
}

.container-big {
	margin-inline: auto;
	max-width: 1680px;
}

.container,
.container-big,
.container-fluid {
	padding-right: var(--container-gap);
	padding-left: var(--container-gap);

	@include media-breakpoint-down(sm) {
		--container-gap: 20px;
	}
}

.content-box {
padding:3rem;
background-color: white;
}
.content {

	ul {
		display: grid;
		gap: .5rem;
		margin-block-end: 1rem;

		li {
			position: relative;
			padding-inline-start: 1.25em;
			@include fluid-type($min_width, $max_width, 16px, 20px);

			&::before {
				position: absolute;
				content: "•";
				top: -.125em;
				left: .25em;
				font-size: 1.25em;
			}
		}
	}

	a {
		&:not(.btn) {
			color: var(--color-primary);
			text-decoration: underline;
		}
	}

	&>h2,
	&>h3,
	&>h4,
	&>h5 {
		&:not(:first-child) {
			margin-block-start: 3rem;
		}
	}

	&[data-content="white"] {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			--title: white;
		}

		--text: white;
	}

	&[data-content="invert"] {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			--title: white;
		}
		.title-info {
			--text: white;
		}

		--text: white;
	}

	&[data-content="white"],
	&[data-content="invert"] {
		.title-primary {
			@extend .title-primary;
		}

		.title-secondary {
			@extend .title-secondary;
		}

		a {
			&:not(.btn) {
				color: var(--color-primary-light);
			}
		}
	}
}

.content-item {
	&:not(:last-child) {
		margin-block-end: 2rem;
	}
}

.col-md-content {
	@include media-breakpoint-down(md) {
		margin-block-end: 2rem;
	}
}

.col-md-order {
	@include media-breakpoint-down(md) {
		order: -1;
	}
}

.col-lg-content {
	@include media-breakpoint-down(lg) {
		margin-block-end: 2rem;
	}
}