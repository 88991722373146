@charset "UTF-8";
/*!
 * Bootstrap Grid v5.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xsm {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 320px) {
  .container-xsm, .container {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .container-sm, .container-xsm, .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xsm, .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1340px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1280px;
  }
}
.row {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 320px) {
  .col-xsm {
    flex: 1 0 0%;
  }
  .row-cols-xsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xsm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xsm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xsm-0 {
    margin-left: 0;
  }
  .offset-xsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xsm-3 {
    margin-left: 25%;
  }
  .offset-xsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xsm-6 {
    margin-left: 50%;
  }
  .offset-xsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xsm-9 {
    margin-left: 75%;
  }
  .offset-xsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xsm-0,
.gx-xsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xsm-0,
.gy-xsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xsm-1,
.gx-xsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xsm-1,
.gy-xsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xsm-2,
.gx-xsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xsm-2,
.gy-xsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xsm-3,
.gx-xsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xsm-3,
.gy-xsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xsm-4,
.gx-xsm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xsm-4,
.gy-xsm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xsm-5,
.gx-xsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xsm-5,
.gy-xsm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1340px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 320px) {
  .d-xsm-inline {
    display: inline !important;
  }
  .d-xsm-inline-block {
    display: inline-block !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-grid {
    display: grid !important;
  }
  .d-xsm-table {
    display: table !important;
  }
  .d-xsm-table-row {
    display: table-row !important;
  }
  .d-xsm-table-cell {
    display: table-cell !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .d-xsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xsm-none {
    display: none !important;
  }
  .flex-xsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xsm-row {
    flex-direction: row !important;
  }
  .flex-xsm-column {
    flex-direction: column !important;
  }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xsm-center {
    justify-content: center !important;
  }
  .justify-content-xsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xsm-start {
    align-items: flex-start !important;
  }
  .align-items-xsm-end {
    align-items: flex-end !important;
  }
  .align-items-xsm-center {
    align-items: center !important;
  }
  .align-items-xsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xsm-start {
    align-content: flex-start !important;
  }
  .align-content-xsm-end {
    align-content: flex-end !important;
  }
  .align-content-xsm-center {
    align-content: center !important;
  }
  .align-content-xsm-between {
    align-content: space-between !important;
  }
  .align-content-xsm-around {
    align-content: space-around !important;
  }
  .align-content-xsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xsm-auto {
    align-self: auto !important;
  }
  .align-self-xsm-start {
    align-self: flex-start !important;
  }
  .align-self-xsm-end {
    align-self: flex-end !important;
  }
  .align-self-xsm-center {
    align-self: center !important;
  }
  .align-self-xsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xsm-stretch {
    align-self: stretch !important;
  }
  .order-xsm-first {
    order: -1 !important;
  }
  .order-xsm-0 {
    order: 0 !important;
  }
  .order-xsm-1 {
    order: 1 !important;
  }
  .order-xsm-2 {
    order: 2 !important;
  }
  .order-xsm-3 {
    order: 3 !important;
  }
  .order-xsm-4 {
    order: 4 !important;
  }
  .order-xsm-5 {
    order: 5 !important;
  }
  .order-xsm-last {
    order: 6 !important;
  }
  .m-xsm-0 {
    margin: 0 !important;
  }
  .m-xsm-1 {
    margin: 0.25rem !important;
  }
  .m-xsm-2 {
    margin: 0.5rem !important;
  }
  .m-xsm-3 {
    margin: 1rem !important;
  }
  .m-xsm-4 {
    margin: 1.5rem !important;
  }
  .m-xsm-5 {
    margin: 3rem !important;
  }
  .m-xsm-auto {
    margin: auto !important;
  }
  .mx-xsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xsm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xsm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xsm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xsm-0 {
    margin-top: 0 !important;
  }
  .mt-xsm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xsm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xsm-3 {
    margin-top: 1rem !important;
  }
  .mt-xsm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xsm-5 {
    margin-top: 3rem !important;
  }
  .mt-xsm-auto {
    margin-top: auto !important;
  }
  .me-xsm-0 {
    margin-right: 0 !important;
  }
  .me-xsm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xsm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xsm-3 {
    margin-right: 1rem !important;
  }
  .me-xsm-4 {
    margin-right: 1.5rem !important;
  }
  .me-xsm-5 {
    margin-right: 3rem !important;
  }
  .me-xsm-auto {
    margin-right: auto !important;
  }
  .mb-xsm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xsm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xsm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xsm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xsm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xsm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xsm-auto {
    margin-bottom: auto !important;
  }
  .ms-xsm-0 {
    margin-left: 0 !important;
  }
  .ms-xsm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xsm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xsm-3 {
    margin-left: 1rem !important;
  }
  .ms-xsm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xsm-5 {
    margin-left: 3rem !important;
  }
  .ms-xsm-auto {
    margin-left: auto !important;
  }
  .p-xsm-0 {
    padding: 0 !important;
  }
  .p-xsm-1 {
    padding: 0.25rem !important;
  }
  .p-xsm-2 {
    padding: 0.5rem !important;
  }
  .p-xsm-3 {
    padding: 1rem !important;
  }
  .p-xsm-4 {
    padding: 1.5rem !important;
  }
  .p-xsm-5 {
    padding: 3rem !important;
  }
  .px-xsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xsm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xsm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xsm-0 {
    padding-top: 0 !important;
  }
  .pt-xsm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xsm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xsm-3 {
    padding-top: 1rem !important;
  }
  .pt-xsm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xsm-5 {
    padding-top: 3rem !important;
  }
  .pe-xsm-0 {
    padding-right: 0 !important;
  }
  .pe-xsm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xsm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xsm-3 {
    padding-right: 1rem !important;
  }
  .pe-xsm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xsm-5 {
    padding-right: 3rem !important;
  }
  .pb-xsm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xsm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xsm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xsm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xsm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xsm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xsm-0 {
    padding-left: 0 !important;
  }
  .ps-xsm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xsm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xsm-3 {
    padding-left: 1rem !important;
  }
  .ps-xsm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xsm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1340px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* noto-sans-jp-regular - latin */
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.woff2") format("woff2"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.woff") format("woff"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
/* noto-sans-jp-500 - latin */
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.woff2") format("woff2"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.woff") format("woff"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
/* noto-sans-jp-700 - latin */
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.woff2") format("woff2"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.woff") format("woff"), url("../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}
/* Genos GFG */
/* genos-gfg - regular*/
@font-face {
  font-family: "GenosGFG";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/genos-gfg/GenosGFG-Regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/genos-gfg/GenosGFG-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/genos-gfg/GenosGFG-Regular.woff2") format("woff2"), url("../fonts/genos-gfg/GenosGFG-Regular.woff") format("woff"), url("../fonts/genos-gfg/GenosGFG-Regular.ttf") format("truetype"), url("../fonts/genos-gfg/GenosGFG-Regular.svg") format("svg"); /* Legacy iOS */
}
/* genos-gfg - regular italic*/
@font-face {
  font-family: "GenosGFG";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/genos-gfg/GenosGFG-RegularItalic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/genos-gfg/GenosGFG-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/genos-gfg/GenosGFG-RegularItalic.woff2") format("woff2"), url("../fonts/genos-gfg/GenosGFG-RegularItalic.woff") format("woff"), url("../fonts/genos-gfg/GenosGFG-RegularItalic.ttf") format("truetype"), url("../fonts/genos-gfg/GenosGFG-RegularItalic.svg") format("svg"); /* Legacy iOS */
}
/* genos-gfg - condensed*/
@font-face {
  font-family: "GenosGFG";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/genos-gfg/GenosGFG-Condensed.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/genos-gfg/GenosGFG-Condensed.eot?#iefix") format("embedded-opentype"), url("../fonts/genos-gfg/GenosGFG-Condensed.woff2") format("woff2"), url("../fonts/genos-gfg/GenosGFG-Condensed.woff") format("woff"), url("../fonts/genos-gfg/GenosGFG-Condensed.ttf") format("truetype"), url("../fonts/genos-gfg/GenosGFG-Condensed.svg") format("svg"); /* Legacy iOS */
}
/* genos-gfg - bold */
@font-face {
  font-family: "GenosGFG";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/genos-gfg/GenosGFG-Bold.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/genos-gfg/GenosGFG-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/genos-gfg/GenosGFG-Bold.woff2") format("woff2"), url("../fonts/genos-gfg/GenosGFG-Bold.woff") format("woff"), url("../fonts/genos-gfg/GenosGFG-Bold.ttf") format("truetype"), url("../fonts/genos-gfg/GenosGFG-Bold.svg") format("svg"); /* Legacy iOS */
}
/* genos-gfg - bold italic */
@font-face {
  font-family: "GenosGFG";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/genos-gfg/GenosGFG-BoldItalic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/genos-gfg/GenosGFG-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/genos-gfg/GenosGFG-BoldItalic.woff2") format("woff2"), url("../fonts/genos-gfg/GenosGFG-BoldItalic.woff") format("woff"), url("../fonts/genos-gfg/GenosGFG-BoldItalic.ttf") format("truetype"), url("../fonts/genos-gfg/GenosGFG-BoldItalic.svg") format("svg"); /* Legacy iOS */
}
:root {
  --color-primary: rgb(0,102, 179);
  --color-secondary: rgb(255, 102, 0);
  --color-tertiary: rgb(0, 45, 103);
  --color-tertiary-1: rgb(69, 90, 100);
  --color-tertiary-2: rgb(120, 144, 156);
  --color-tertiary-3: rgb(176, 190, 197);
  --color-tertiary-4: rgb(236, 239, 241);
  --color-text:rgb(51, 51, 51);
  --color-primary-1: rgb(50, 125, 200);
  --color-primary-2: rgb(100, 155, 215);
  --color-primary-3: rgb(150, 190, 230);
  --color-primary-4: rgb(225, 235, 245);
  --btn-primary: #0066b3;
  --btn-primary-hover: #00579a;
  --btn-secondary: #ff6600;
  --btn-secondary-hover: #b84900;
  --btn-dark: #012f63;
  --btn-dark-hover: #0251aa;
  --btn-neutral: #b0bec5;
  --btn-neutral-hover: #93a6af;
  --btn-light: #ECEFF1;
  --btn-light-hover: #cfd6db;
  --title: var(--color-tertiary);
  --text: var(--color-text);
  --meta: var(--color-primary-1);
  --link: var(--color-secondary);
  --bg-primary: var(--color-primary);
  --bg-secondary: var(--color-secondary);
  --bg-dark: var(--color-tertiary);
  --bg-light: var(--color-tertiary-4);
  --bg-gradient-rgb: 0, 45, 103;
  --border: var(--color-tertiary-3);
  --border-light: var(--color-tertiary-4);
  --font-title: "GenosGFG", Arial, sans-serif;
  --font-main: "GenosGFG", Arial, sans-serif;
  --font-size: 20px;
  --font-weight: 400;
  --line-height: 1.6;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: var(--font-main);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  color: var(--text);
  overflow-x: hidden;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
@media (max-width: 991.98px) {
  body.paddingtop {
    padding-block: 100px 0;
  }
}

h1 {
  margin: 0 0 2rem 0;
  padding: 0;
  font-weight: 500;
  line-height: 1.2;
  color: var(--title);
}
h1 {
  font-size: 32px;
}
@media screen and (min-width: 576px) {
  h1 {
    font-size: calc(32px + 18 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  h1 {
    font-size: 50px;
  }
}

h2 {
  margin: 0 0 1.5rem 0;
  padding: 0;
  font-weight: 500;
  line-height: 1.2;
  color: var(--title);
}
h2 {
  font-size: 28px;
}
@media screen and (min-width: 576px) {
  h2 {
    font-size: calc(28px + 12 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  h2 {
    font-size: 40px;
  }
}

h3 {
  margin: 0 0 1rem 0;
  padding: 0;
  font-weight: 500;
  line-height: 1.3;
  color: var(--title);
}
h3 {
  font-size: 24px;
}
@media screen and (min-width: 576px) {
  h3 {
    font-size: calc(24px + 8 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  h3 {
    font-size: 32px;
  }
}

h4 {
  margin: 0 0 1rem 0;
  padding: 0;
  line-height: 1.4;
  color: var(--title);
}
h4 {
  font-size: 20px;
}
@media screen and (min-width: 576px) {
  h4 {
    font-size: calc(20px + 4 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  margin: 0 0 1rem 0;
  padding: 0;
  font-size: var(--font-size);
  line-height: 1.6;
  color: var(--title);
}

p {
  margin: 0 0 1rem 0;
  color: var(--text);
}
p {
  font-size: 16px;
}
@media screen and (min-width: 576px) {
  p {
    font-size: calc(16px + 4 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  p {
    font-size: 20px;
  }
}
p:last-child {
  margin: 0;
}
p.zitat {
  font-style: italic;
  color: var(--color-tertiary);
}
p.zitat {
  font-size: 18px;
}
@media screen and (min-width: 576px) {
  p.zitat {
    font-size: calc(18px + 14 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  p.zitat {
    font-size: 32px;
  }
}
p.bildunterschrift {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

a {
  color: var(--color-primary);
}

[data-font=hero] {
  font-family: var(--font-title);
  color: var(--color-secondary);
  font-style: italic;
}
[data-font=hero] span {
  font-family: var(--font-main);
  font-style: initial;
  color: white;
}

.title-info {
  font-weight: 600;
  color: var(--text);
}

.title-serif {
  font-family: var(--font-title);
  font-style: italic;
}

.title-h1 {
  font-size: 32px;
}
@media screen and (min-width: 576px) {
  .title-h1 {
    font-size: calc(32px + 18 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .title-h1 {
    font-size: 50px;
  }
}

.title-primary, .content[data-content=white] .title-primary, .content[data-content=invert] .title-primary {
  --title: var(--color-primary);
}

.title-secondary, .content[data-content=white] .title-secondary, .content[data-content=invert] .title-secondary {
  --title: var(--color-secondary);
}

.primary {
  color: var(--color-primary);
}

.secondary {
  color: var(--color-secondary);
}

b,
strong,
.bold {
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  margin: 0;
  padding: 0;
}

.mt-1 {
  margin-block-start: 1rem !important;
}

.mt-2 {
  margin-block-start: 2rem !important;
}

.mt-3 {
  margin-block-start: 3rem !important;
}

.mt-4 {
  margin-block-start: 4rem !important;
}

.mt-5 {
  margin-block-start: 5rem !important;
}

.mb-1 {
  margin-block-end: 1rem !important;
}

.mb-2 {
  margin-block-end: 2rem !important;
}

.mb-3 {
  margin-block-end: 3rem !important;
}

.mb-4 {
  margin-block-end: 4rem !important;
}

.mb-5 {
  margin-block-end: 5rem !important;
}

.socialicons {
  display: flex;
  justify-content: center;
}
.socialicons ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
.socialicons ul li {
  margin-left: 5px;
  margin-right: 5px;
}

.btn {
  display: inline-block;
  padding-inline: 2rem;
  padding-block: 0.75rem;
  color: white;
  font-weight: 600;
  background-color: var(--btn-primary);
  border-radius: 50vw;
  text-decoration: none;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.btn {
  font-size: 16px;
}
@media screen and (min-width: 576px) {
  .btn {
    font-size: calc(16px + 4 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .btn {
    font-size: 20px;
  }
}
.btn:hover {
  background-color: var(--btn-primary-hover);
}
.btn--secondary {
  color: #ffffff;
  background-color: var(--btn-secondary);
}
.btn--secondary:hover {
  background-color: var(--btn-secondary-hover);
}
.btn--dark {
  background-color: var(--btn-dark);
}
.btn--dark:hover {
  background-color: var(--btn-dark-hover);
}
.btn--neutral {
  color: var(--title);
  background-color: var(--btn-neutral);
}
.btn--neutral:hover {
  color: var(--title);
  background-color: var(--btn-neutral-hover);
}

.btn-bar {
  margin-block-start: 3rem;
}
.btn-bar--mb-4 {
  margin-block-end: 4rem;
}

.logo, .logo-bg {
  position: fixed;
  inset: 50px auto auto 40px;
  display: inline-block;
  z-index: 999;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.25s;
}
@media (max-width: 991.98px) {
  .logo, .logo-bg {
    inset: 20px auto auto 20px;
  }
}
@media (max-width: 575.98px) {
  .logo, .logo-bg {
    inset: 30px auto auto 20px;
  }
}
.logo.hide, .logo-bg.hide {
  transform: translateY(-20px);
  opacity: 0;
  visibility: hidden;
}
.logo img, .logo-bg img {
  width: 240px;
  height: auto;
}
@media (max-width: 991.98px) {
  .logo img, .logo-bg img {
    width: 180px;
  }
}
@media (max-width: 575.98px) {
  .logo img, .logo-bg img {
    width: 140px;
  }
}

.logo-bg {
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  background-color: #ffffff;
  border-radius: 0 0 7px 7px;
  inset: 0 auto auto 40px;
}
@media (max-width: 575.98px) {
  .logo-bg {
    inset: 0 auto auto 20px;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
  }
}

[data-animation=default] {
  opacity: 0;
}

[data-animation=fade-in] {
  opacity: 0;
  transform: translateY(100px);
}

.transition-overlay {
  position: fixed;
  inset: 0;
  display: grid;
  grid-auto-flow: column;
  z-index: 9999;
  visibility: hidden;
}
.transition-overlay__tile {
  position: relative;
  width: 102%;
  height: 100%;
  transform: translateY(100%);
  background-color: var(--color-tertiary);
}

.figure {
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .figure {
    margin-block: 1.25rem;
  }
}
.figure--md {
  max-width: 640px;
}
.figure--sm {
  max-width: 480px;
}
.figure--xs {
  max-width: 320px;
}
.figure .img {
  width: 100%;
  height: auto;
  display: block;
}
.figure .img-diagramm {
  width: 100%;
  max-width: 160px;
  height: auto;
  display: block;
}
.figure .figcaption {
  margin-block-start: 0.5rem;
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  color: var(--meta);
}

[data-img-size="1/1"] {
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

[data-img-size="16/9"] {
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

[data-img-position=top] {
  object-position: center 0 !important;
}

[data-img-position=top-mid] {
  object-position: center 25% !important;
}

[data-img-position=mid-bottom] {
  object-position: center 75% !important;
}

[data-img-position=bottom] {
  object-position: center 100% !important;
}

.video {
  width: 100%;
  aspect-ratio: 16/9;
}
.video iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.video-cta {
  position: relative;
  display: block;
}
.video-cta:hover::before {
  background-color: var(--btn-secondary-hover);
}
.video-cta::before {
  content: "";
  position: absolute;
  inset: 50% auto auto 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--btn-secondary);
  background-image: url("../img/icon/icon-play.svg");
  background-position: left 55% top 50%;
  background-size: 33%;
  background-repeat: no-repeat;
  translate: -50% -50%;
  z-index: 5;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
@media (max-width: 767.98px) {
  .video-cta::before {
    width: 60px;
    height: 60px;
  }
}

.footer {
  background-color: var(--bg-light);
  padding-block: 4rem;
  text-align: center;
}
.footer__list {
  display: flex;
  justify-content: center;
  margin-block-end: 0.5rem;
}
@media (max-width: 767.98px) {
  .footer__list {
    margin-block-end: 0.25rem;
  }
}
.footer__item {
  position: relative;
  margin-inline-end: 0.5rem;
  font-weight: 400;
}
.footer__item:not(:last-child)::after {
  content: "/";
  position: relative;
  display: inline-block;
  margin-inline-start: 0.5rem;
}
.footer__link {
  color: var(--text);
  text-decoration: none;
}
.footer__link {
  font-size: 16px;
}
@media screen and (min-width: 576px) {
  .footer__link {
    font-size: calc(16px + 4 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .footer__link {
    font-size: 20px;
  }
}
.footer__link:hover {
  color: var(--meta);
}
.footer__copy {
  color: var(--meta);
  font-size: 16px;
}
@media (max-width: 575.98px) {
  .footer__copy {
    font-size: 14px;
  }
}

.hero {
  position: relative;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: var(--bg-dark);
}

.hero-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 0;
}
.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.hero-title {
  margin-block: 55vh 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.hero-title[data-shadow=text],
.hero-title [data-shadow=text] {
  text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
@media (max-width: 991.98px) {
  .hero-title[data-shadow=text],
.hero-title [data-shadow=text] {
    text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5), 0 5px 20px rgba(0, 0, 0, 0.5), 0 3px 7px rgba(0, 0, 0, 0.5);
  }
}
.hero-title .smaller {
  font-size: 48px;
  display: inline;
  line-height: 60px;
  background-color: white;
  text-shadow: none;
  color: var(--color-tertiary);
  margin-block: 0.15rem;
}
.hero-title .smaller.orange {
  background-color: var(--color-secondary);
  color: white;
}
@media (max-width: 575.98px) {
  .hero-title .smaller {
    font-size: 26px;
    line-height: 34px;
  }
}

.hero-detail {
  position: relative;
  background-color: var(--bg-dark);
  width: 100%;
  height: 100%;
  max-height: 800px;
  aspect-ratio: 3/2;
}
.hero-detail[data-size=full] {
  height: 100vh;
  max-height: initial;
  aspect-ratio: initial;
}
@media (max-width: 991.98px) {
  .hero-detail[data-size=full] {
    height: initial;
    aspect-ratio: 5/3;
  }
}
.hero-detail .image {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 0;
}
.hero-detail .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-title-year {
  display: inline-block;
  color: white;
  font-weight: 600;
}
.hero-title-year {
  font-size: 24px;
}
@media screen and (min-width: 576px) {
  .hero-title-year {
    font-size: calc(24px + 24 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .hero-title-year {
    font-size: 48px;
  }
}

.hero-title-box {
  background-color: var(--bg-dark);
  height: 100%;
  width: auto;
  display: inline-block;
  padding: 1em 2em;
}
@media (max-width: 575.98px) {
  .hero-title-box {
    padding: 1em;
  }
}
.hero-title-box h1 {
  margin-block-end: initial;
  display: inline-block;
  position: relative;
  color: white;
  z-index: 10;
  font-weight: 600;
}
.hero-title-box h1 {
  font-size: 32px;
}
@media screen and (min-width: 576px) {
  .hero-title-box h1 {
    font-size: calc(32px + 48 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .hero-title-box h1 {
    font-size: 80px;
  }
}
.hero-title-box p {
  color: white;
  font-weight: 400;
}
.hero-title-box p {
  font-size: 20px;
}
@media screen and (min-width: 576px) {
  .hero-title-box p {
    font-size: calc(20px + 12 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .hero-title-box p {
    font-size: 32px;
  }
}

:root {
  --section-pt: 150px;
  --container-gap: 40px;
}

main {
  display: block;
}

.section {
  padding-block: var(--section-pt);
}
@media (max-width: 1199.98px) {
  .section {
    --section-pt: 100px;
  }
}
@media (max-width: 575.98px) {
  .section {
    --section-pt: 50px;
  }
}
@media (max-width: 767.98px) {
  .section--first {
    --section-pt: 150px;
  }
}
@media (max-width: 575.98px) {
  .section--first {
    --section-pt: 150px;
  }
}
.section[data-bg=dark] {
  background-color: var(--bg-dark);
}
.section[data-bg=light] {
  background-color: var(--bg-light);
}
.section[data-size=min] {
  min-height: 100vh;
}
.section[data-section=story] {
  position: relative;
  padding-block: inherit;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.section[data-section=story] .box {
  align-self: center;
  position: relative;
  margin-block-start: -100vh;
  margin-inline: auto;
  padding-inline: 5rem;
  padding-block: 25vh;
  width: 100%;
  max-width: 800px;
  z-index: 10;
}
.section[data-section=story] .box[data-position=bottom] {
  align-self: flex-end;
  padding-block: 0 3rem;
}
.section[data-section=story] .box--right {
  grid-column: 2/-1;
}
@media (max-width: 1199.98px) {
  .section[data-section=story] .box {
    padding-inline: inherit;
    grid-column: span 2;
  }
}
.section[data-section=story] .image {
  grid-column: 1/-1;
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 5;
  overflow: hidden;
}
.section[data-section=story] .image img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.section[data-section=full] {
  position: relative;
  display: grid;
  padding-block: initial;
  min-height: 100vh;
}
@media (max-width: 767.98px) {
  .section[data-section=full] {
    min-height: initial;
  }
}
.section[data-section=full] .image {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 0;
}
@media (max-width: 767.98px) {
  .section[data-section=full] .image {
    position: relative;
    inset: inherit;
    aspect-ratio: 5/3;
  }
}
.section[data-section=full] .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section[data-section=full] .box {
  align-self: center;
  padding-block: 100px;
  width: 100%;
  z-index: 10;
}
@media (max-width: 767.98px) {
  .section[data-section=full] .box {
    padding-block: 0;
  }
}
.section[data-section=full] .box[data-position=start] {
  align-self: flex-start;
}
.section[data-section=full] .box[data-position=end] {
  align-self: flex-end;
}
@media (max-width: 767.98px) {
  .section[data-section=full] .container {
    padding-right: 0;
    padding-left: 0;
  }
}
.section[data-section=full] .content-box {
  padding: 3rem;
  background-color: white;
}
@media (max-width: 767.98px) {
  .section[data-section=full] .content-box {
    padding-inline: 0;
  }
}
.section[data-section=full] .content-box[data-bg=dark] {
  background-color: var(--bg-dark);
}
.section[data-section=full] .content-box[data-bg=light] {
  background-color: var(--bg-light);
}
@media (max-width: 767.98px) {
  .section[data-section=full] .content-box {
    padding-right: var(--container-gap);
    padding-left: var(--container-gap);
  }
}
.section[data-section=detail] {
  position: relative;
  padding-block-start: inherit;
}
.section[data-section=detail] .image {
  position: relative;
  margin-inline: auto;
  aspect-ratio: 2/1;
}
.section[data-section=detail] .image::after {
  content: "";
  position: absolute;
  inset: auto 0 0;
  width: 100vw;
  height: clamp(50px, 15vw, 150px);
  background: linear-gradient(180deg, rgba(var(--bg-gradient-rgb), 0.9) 0%, rgba(var(--bg-gradient-rgb), 1) 90%);
}
.section[data-section=detail] .image img {
  object-fit: cover;
  object-position: center top;
  height: 100%;
  width: 100%;
}
.section[data-section=half] {
  position: relative;
  padding-block: inherit;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 100vh;
}
@media (max-width: 1199.98px) {
  .section[data-section=half] {
    min-height: 75vh;
  }
}
@media (max-width: 767.98px) {
  .section[data-section=half] {
    grid-template-columns: repeat(1, 1fr);
    min-height: inherit;
  }
}
.section[data-section=half] .image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .section[data-section=half] .image {
    width: 100%;
    aspect-ratio: 1/1;
    order: 1;
  }
}
.section[data-section=half] .image img {
  object-fit: cover;
  object-position: center top;
  height: 100%;
  width: 100%;
}
.section[data-section=half] .box {
  margin-inline: auto;
  padding-block: 2rem;
  max-width: 640px;
  align-self: center;
}
@media (max-width: 767.98px) {
  .section[data-section=half] .box {
    order: 2;
    padding-block-end: 4rem;
  }
}

.section-item:not(:last-child) {
  margin-bottom: 4rem;
}
@media (max-width: 767.98px) {
  .section-item:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media (max-width: 575.98px) {
  .section-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.container-big {
  margin-inline: auto;
  max-width: 1680px;
}

.container,
.container-big,
.container-fluid,
.container-xsm,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-right: var(--container-gap);
  padding-left: var(--container-gap);
}
@media (max-width: 575.98px) {
  .container,
.container-big,
.container-fluid,
.container-xsm,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    --container-gap: 20px;
  }
}

.content-box {
  padding: 3rem;
  background-color: white;
}

.content ul {
  display: grid;
  gap: 0.5rem;
  margin-block-end: 1rem;
}
.content ul li {
  position: relative;
  padding-inline-start: 1.25em;
}
.content ul li {
  font-size: 16px;
}
@media screen and (min-width: 576px) {
  .content ul li {
    font-size: calc(16px + 4 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .content ul li {
    font-size: 20px;
  }
}
.content ul li::before {
  position: absolute;
  content: "•";
  top: -0.125em;
  left: 0.25em;
  font-size: 1.25em;
}
.content a:not(.btn) {
  color: var(--color-primary);
  text-decoration: underline;
}
.content > h2:not(:first-child), .content > h3:not(:first-child), .content > h4:not(:first-child), .content > h5:not(:first-child) {
  margin-block-start: 3rem;
}
.content[data-content=white] {
  --text: white;
}
.content[data-content=white] h1,
.content[data-content=white] h2,
.content[data-content=white] h3,
.content[data-content=white] h4,
.content[data-content=white] h5,
.content[data-content=white] h6 {
  --title: white;
}
.content[data-content=invert] {
  --text: white;
}
.content[data-content=invert] h1,
.content[data-content=invert] h2,
.content[data-content=invert] h3,
.content[data-content=invert] h4,
.content[data-content=invert] h5,
.content[data-content=invert] h6 {
  --title: white;
}
.content[data-content=invert] .title-info {
  --text: white;
}
.content[data-content=white] a:not(.btn), .content[data-content=invert] a:not(.btn) {
  color: var(--color-primary-light);
}

.content-item:not(:last-child) {
  margin-block-end: 2rem;
}

@media (max-width: 767.98px) {
  .col-md-content {
    margin-block-end: 2rem;
  }
}

@media (max-width: 767.98px) {
  .col-md-order {
    order: -1;
  }
}

@media (max-width: 991.98px) {
  .col-lg-content {
    margin-block-end: 2rem;
  }
}

:root {
  --toggle-btn: var(--color-primary);
  --toggle-icon: white;
  --toggle-btn-current: var(--color-secondary);
  --toggle-icon-current: white;
}

.menu-toggle {
  cursor: pointer;
  position: fixed;
  inset: 40px 40px auto auto;
  width: 80px;
  height: 80px;
  background-color: var(--toggle-btn);
  border-radius: 50%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border: 0;
  z-index: 2000;
}
@media (max-width: 575.98px) {
  .menu-toggle {
    inset: 20px 20px auto auto;
    width: 40px;
    height: 40px;
  }
}
.menu-toggle span {
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  width: 30px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--toggle-icon);
}
.menu-toggle span:nth-child(1) {
  transform: translate(-50%, -9px);
}
.menu-toggle span:nth-child(2) {
  transform: translate(-50%, 0);
}
.menu-toggle span:nth-child(3) {
  transform: translate(-50%, 9px);
}
@media (max-width: 575.98px) {
  .menu-toggle span {
    width: 17px;
    height: 2px;
  }
  .menu-toggle span:nth-child(1) {
    transform: translate(-50%, -6px);
  }
  .menu-toggle span:nth-child(2) {
    transform: translate(-50%, 0);
  }
  .menu-toggle span:nth-child(3) {
    transform: translate(-50%, 6px);
  }
}

.menu {
  display: grid;
  place-items: center;
  position: fixed;
  inset: 0 auto 0 120%;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: auto;
}

.menu-nav {
  padding-inline: 6rem;
  padding-block: 120px;
  width: 100%;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 3em;
}
@media (max-width: 1199.98px) {
  .menu-nav {
    padding-inline: 4rem;
    gap: 3em;
  }
}
@media (max-width: 767.98px) {
  .menu-nav {
    padding-block: 60px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-inline: 2rem;
    gap: 2em;
  }
}
.menu-nav__title {
  position: relative;
  display: block;
  margin-block-end: 0.75em;
  padding-block-end: 0.25em;
  font-weight: 600;
  line-height: 1.6;
  color: var(--color-tertiary);
}
.menu-nav__title {
  font-size: 20px;
}
@media screen and (min-width: 576px) {
  .menu-nav__title {
    font-size: calc(20px + 4 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .menu-nav__title {
    font-size: 24px;
  }
}
.menu-nav__title::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-tertiary);
}
.menu-nav__title[data-color=deafult] {
  color: var(--color-secondary);
}
.menu-nav__title[data-color=deafult]::after {
  background-color: var(--color-secondary);
}
.menu-nav__pic {
  flex-shrink: 0;
  width: 100%;
  max-width: 80px;
  aspect-ratio: 1/1;
}
@media (max-width: 767.98px) {
  .menu-nav__pic {
    max-width: 60px;
  }
}
.menu-nav__item {
  position: relative;
  display: block;
  left: 4rem;
  opacity: 0;
}
.menu-nav__item:not(:last-child) {
  margin-block-end: 0.25em;
}
@media (max-width: 767.98px) {
  .menu-nav__item:not(:last-child) {
    margin-block-end: 0.25em;
  }
}
.menu-nav__link {
  position: relative;
  display: inline-block;
  padding-inline-start: 1.5em;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-tertiary);
  text-decoration: none;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.menu-nav__link {
  font-size: 16px;
}
@media screen and (min-width: 576px) {
  .menu-nav__link {
    font-size: calc(16px + 2 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .menu-nav__link {
    font-size: 18px;
  }
}
.menu-nav__link::after {
  content: "";
  position: absolute;
  top: 0.2em;
  left: 0;
  width: 1em;
  height: 1em;
  border-radius: 50vw;
  background-color: var(--color-tertiary);
  mask-image: url("../img/icon/ic_pfeil-rechts_24.svg");
  mask-position: center;
  mask-size: cover;
}
.menu-nav__link:hover {
  color: var(--color-primary);
}
.menu-nav__link:hover::after {
  background-color: var(--color-primary);
}
.menu-nav__link[aria-current=page] {
  color: var(--color-primary);
}
.menu-nav__link[aria-current=page]::after {
  background-color: var(--color-primary);
}
.menu-nav__link[aria-current=page][onclick] {
  color: inherit;
}

.menu-pdf {
  display: inline-block;
  padding: 0.25rem 1rem;
  color: white;
  font-weight: 600;
  background-color: var(--btn-primary);
  border-radius: 50vw;
  text-decoration: none;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.menu-pdf {
  font-size: 14px;
}
@media screen and (min-width: 576px) {
  .menu-pdf {
    font-size: calc(14px + 2 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .menu-pdf {
    font-size: 16px;
  }
}
.menu-pdf:hover {
  background-color: var(--btn-primary-hover);
}
@media (max-width: 575.98px) {
  .menu-pdf {
    inset: auto 20px 20px auto;
  }
}

.menu-logo {
  position: absolute;
  inset: 40px auto auto 40px;
  margin-right: 2rem;
}
.menu-logo img {
  width: 240px;
  height: auto;
}
@media (max-width: 575.98px) {
  .menu-logo {
    inset: 20px auto auto 20px;
  }
  .menu-logo img {
    width: 200px;
  }
}

:root {
  --sidenav-btn: var(--btn-secondary);
  --sidenav-btn-hover: var(--btn-secondary-hover);
  --sidenav-btn-text: var(--color-tertiary);
  --sidenav-btn-text-hover: var(--color-tertiary);
  --sidenav-popup-bg: var(--color-primary-1);
}

.side-navigation {
  position: fixed;
  inset: auto 40px 40px auto;
  z-index: 500;
}
@media (max-width: 767.98px) {
  .side-navigation {
    inset: auto 20px 20px auto;
    display: none;
  }
}
@media (max-width: 575.98px) {
  .side-navigation {
    inset: auto 0 0 0;
  }
}
.side-navigation__popup {
  position: absolute;
  inset: auto 0 50px auto;
  min-width: 320px;
  padding: 3rem;
  background-color: var(--sidenav-popup-bg);
  border-radius: 10px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  z-index: 5;
}
.side-navigation__popup::after {
  top: 100%;
  right: 30px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: var(--sidenav-popup-bg);
  border-width: 10px;
  margin-left: -10px;
}
@media (max-width: 575.98px) {
  .side-navigation__popup {
    inset: auto 0 30px 0;
    padding: 2rem;
    border-radius: 0;
  }
  .side-navigation__popup::after {
    display: none;
  }
}
.side-navigation__title {
  margin-block-end: 1rem;
  font-size: 18px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
}
.side-navigation__item:not(:last-child) {
  margin-block-end: 0.5rem;
}
.side-navigation__link {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.side-navigation__link:hover {
  color: white;
  text-underline-position: above;
}
.side-navigation__link.current {
  color: white;
}
.side-navigation__link.current::before {
  content: "•";
  position: absolute;
  top: -0.15rem;
  left: -1rem;
  color: var(--color-primary);
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.side-navigation__button {
  position: relative;
  cursor: pointer;
  display: block;
  max-width: 600px;
  padding-inline: 2rem;
  min-width: 50px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: var(--sidenav-btn);
  border: 0;
  border-radius: 50vh;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  z-index: 10;
}
.side-navigation__button:hover {
  background-color: var(--sidenav-btn-hover);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}
@media (max-width: 575.98px) {
  .side-navigation__button {
    width: 100%;
    border-radius: 0;
  }
}

.side-control {
  position: fixed;
  inset: auto 40px 40px auto;
  display: flex;
  transform: translateX(70px);
  z-index: 500;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
@media (max-width: 767.98px) {
  .side-control {
    display: none;
    pointer-events: none;
    top: 100% !important;
    opacity: 0;
  }
}
@media (max-width: 575.98px) {
  .side-control {
    inset: auto 0 0 auto;
    transform: translateX(50px);
  }
}
.side-control__button-back, .side-control__button-top {
  cursor: pointer;
  position: relative;
  display: grid;
  place-items: center;
  width: 60px;
  height: 60px;
  background-color: var(--sidenav-btn);
  border: 0;
  border-radius: 50vh;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.side-control__button-back::after, .side-control__button-top::after {
  content: "";
  position: relative;
  width: 20px;
  height: 20px;
  background-color: white;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: cover;
}
.side-control__button-back:not(:first-child), .side-control__button-top:not(:first-child) {
  margin-inline-start: 10px;
}
.side-control__button-back:hover, .side-control__button-top:hover {
  background-color: var(--sidenav-btn-hover);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}
@media (max-width: 575.98px) {
  .side-control__button-back, .side-control__button-top {
    width: 50px;
    height: 50px;
    border-radius: 0;
    box-shadow: none;
  }
  .side-control__button-back:not(:first-child), .side-control__button-top:not(:first-child) {
    margin-inline-start: 1px;
  }
  .side-control__button-back:hover, .side-control__button-top:hover {
    box-shadow: none;
  }
}
.side-control__button-back::after {
  mask-image: url("../img/icon/icon-arrow-left.svg");
}
.side-control__button-top {
  pointer-events: none;
  opacity: 0;
}
.side-control__button-top::after {
  mask-image: url("../img/icon/icon-arrow-top.svg");
}
.side-control.show {
  transform: translateX(0);
}
.side-control.show .side-control__button-back,
.side-control.show .side-control__button-top {
  pointer-events: auto;
  opacity: 1;
}

.stepnavigation-left, .stepnavigation-right {
  position: fixed;
  display: flex;
  align-items: center;
  width: 120px;
  height: 160px;
  transform: translateY(-50%);
  z-index: 400;
}
@media (max-width: 767.98px) {
  .stepnavigation-left, .stepnavigation-right {
    transform: translateY(0);
    width: 50vw;
    height: 40px;
  }
}
.stepnavigation__btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: var(--color-primary-1);
  border-radius: 50vw;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 767.98px) {
  .stepnavigation__btn {
    width: 100%;
    height: 40px;
    border-radius: 0;
  }
}
.stepnavigation__btn::after {
  content: "";
  position: relative;
  width: 20px;
  height: 20px;
  background-color: white;
  mask-image: url("../img/icon/icon-arrow-right.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 20px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 767.98px) {
  .stepnavigation__btn::after {
    opacity: 1;
  }
}
.stepnavigation__title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 80px;
  padding-inline: 5rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 80px;
  color: white;
  background-color: var(--color-primary-1);
  border-radius: 50vw;
  animation: titleOut 0.15s forwards;
  opacity: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .stepnavigation__title {
    display: none;
  }
}
.stepnavigation-left {
  justify-content: flex-end;
  inset: 50% auto auto 0;
}
@media (max-width: 767.98px) {
  .stepnavigation-left {
    inset: auto auto 0 0;
  }
  .stepnavigation-left .stepnavigation__btn {
    transform: translateX(0) scale(1) !important;
    border-right: 1px solid white;
  }
}
.stepnavigation-left .stepnavigation__btn {
  transform: translateX(-80px) scale(0.75);
}
.stepnavigation-left .stepnavigation__btn::after {
  mask-image: url("../img/icon/icon-arrow-left.svg");
}
.stepnavigation-left .stepnavigation__title {
  padding-inline: 5rem 2rem;
  left: 0;
}
.stepnavigation-left:hover .stepnavigation__btn {
  max-width: auto;
  transform: translateX(0) scale(1);
}
.stepnavigation-left:hover .stepnavigation__btn::after {
  opacity: 1;
}
.stepnavigation-left:hover .stepnavigation__title {
  animation: titleIn 0.3s forwards 0.5s;
}
.stepnavigation-right {
  justify-content: flex-start;
  inset: 50% 0 auto auto;
}
@media (max-width: 767.98px) {
  .stepnavigation-right {
    inset: auto 0 0 auto;
  }
  .stepnavigation-right .stepnavigation__btn {
    transform: translateX(0) scale(1) !important;
  }
}
.stepnavigation-right .stepnavigation__btn {
  transform: translateX(80px) scale(0.75);
}
.stepnavigation-right .stepnavigation__btn::after {
  mask-image: url("../img/icon/icon-arrow-right.svg");
}
.stepnavigation-right .stepnavigation__title {
  padding-inline: 2rem 5rem;
  right: 0;
}
.stepnavigation-right:hover .stepnavigation__btn {
  max-width: auto;
  transform: translateX(0) scale(1);
}
.stepnavigation-right:hover .stepnavigation__btn::after {
  opacity: 1;
}
.stepnavigation-right:hover .stepnavigation__title {
  animation: titleIn 1s forwards 0.5s;
}
.stepnavigation--deactive {
  display: none;
}
@keyframes titleIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes titleOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.side-teaser {
  pointer-events: none;
  position: fixed;
  inset: auto auto 40px 40px;
  display: block;
  padding: 1rem;
  width: 100%;
  max-width: 280px;
  font-size: 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
  z-index: 900;
  opacity: 0;
}
@media (max-width: 767.98px) {
  .side-teaser {
    inset: auto auto 20px 20px;
  }
}
.side-teaser__close {
  cursor: pointer;
  position: absolute;
  inset: -10px -10px auto auto;
  width: 30px;
  height: 30px;
  background-color: var(--btn-secondary);
  border-radius: 50vw;
  border: 0;
}
.side-teaser__close::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: white;
  mask-image: url("../img/icon/icon-close.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 10px;
}
.side-teaser__close:hover {
  background-color: var(--btn-secondary-hover);
}
.side-teaser__link {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  text-decoration: none;
}
.side-teaser__link:hover .side-teaser__img {
  scale: 1.1;
}
.side-teaser__title {
  font-weight: 600;
  line-height: 1.2;
}
.side-teaser__img {
  flex-shrink: 0;
  margin-right: 1rem;
  width: 60px;
  height: auto;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

:root {
  --acc-btn: var(--btn-light);
  --acc-btn-hover: var(--btn-light-hover);
  --acc-btn-title: var(--title);
  --acc-btn-icon: var(--title);
}

.accordion__item--show .accordion__info {
  height: auto;
  opacity: 1;
}
.accordion__button {
  cursor: pointer;
  display: block;
  position: relative;
  background: none;
  border: 0;
  width: 100%;
  padding: 1.25rem 3rem 1.25rem 1.5rem;
  font-weight: 600;
  line-height: 1.4;
  color: var(--acc-btn-title);
  text-align: left;
  background-color: var(--acc-btn);
  border-radius: 2rem;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.accordion__button {
  font-size: 16px;
}
@media screen and (min-width: 576px) {
  .accordion__button {
    font-size: calc(16px + 4 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .accordion__button {
    font-size: 20px;
  }
}
.accordion__button::after {
  align-self: flex-start;
  flex-shrink: 0;
  position: absolute;
  top: 1.5rem;
  right: 1.75rem;
  content: "";
  width: 16px;
  height: 16px;
  margin-inline-start: 1rem;
  background-color: var(--acc-btn-icon);
  mask-image: url("../img/icon/icon-chevron-down.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 16px;
}
.accordion__button:hover {
  color: var(--acc-btn-title);
  background-color: var(--acc-btn-hover);
}
.accordion__button:hover::after {
  mask-image: url("../img/icon/icon-chevron-down.svg");
}
.accordion__info {
  height: 0;
  opacity: 0;
  padding: 0 1.5rem;
  background-color: transparent;
  overflow: hidden;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
@media (max-width: 575.98px) {
  .accordion__info {
    padding: 0 0.5rem;
  }
}
.accordion__item {
  margin-block-end: 1rem;
}
.accordion__item.accordion__item--show .accordion__button {
  color: var(--acc-btn-title);
  background-color: var(--acc-btn-hover);
}
.accordion__item.accordion__item--show .accordion__button::after {
  mask-image: url("../img/icon/icon-chevron-up.svg");
}
.accordion__item.accordion__item--show .accordion__info {
  padding: 2rem 1.5rem 3rem;
}
@media (max-width: 575.98px) {
  .accordion__item.accordion__item--show .accordion__info {
    padding: 2rem 0.5rem 3rem;
  }
}
.accordion[data-content=primary] {
  --acc-btn: var(--btn-primary);
  --acc-btn-hover: var(--btn-primary-hover);
  --acc-btn-title: white;
  --acc-btn-icon: white;
}
.accordion[data-content=secondary] {
  --acc-btn: var(--btn-secondary);
  --acc-btn-hover: var(--btn-secondary-hover);
  --acc-btn-title: white;
  --acc-btn-icon: white;
}

.table-default {
  overflow-x: auto;
}
.table-default table {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  border-collapse: collapse;
}
.table-default tr {
  width: 100%;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.table-default td,
.table-default th {
  width: 100%;
  text-align: left;
  min-width: 200px;
  padding: 1rem;
}
.table-default td:first-child,
.table-default th:first-child {
  padding-inline-start: 0.5rem;
}
@media (max-width: 575.98px) {
  .table-default td,
.table-default th {
    padding: 0.5rem;
    min-width: 100px;
  }
}
.table-default thead tr {
  border-bottom: 2px solid var(--text);
}
.table-default thead th {
  vertical-align: bottom;
}
.table-default thead th span {
  font-size: 32px;
  color: var(--color-primary);
}
.table-default thead th span.smaller {
  font-size: 18px;
}
.table-default tbody tr {
  border-bottom: 1px solid var(--border);
}
.table-default tbody tr:hover {
  background-color: var(--bg-light);
}
.table-default__highlight {
  background-color: white;
}
.table-default__title {
  font-size: 24px;
}
.table-default__meta {
  min-width: 100px !important;
  text-align: right !important;
}

.counter-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
@media (max-width: 1199.98px) {
  .counter-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991.98px) {
  .counter-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575.98px) {
  .counter-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.counter-grid__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  aspect-ratio: 1/1;
  background-color: var(--bg-light);
  text-align: center;
}
@media (max-width: 575.98px) {
  .counter-grid__item {
    aspect-ratio: inherit;
  }
}
.counter-grid__item[data-bg=border] {
  background-color: white;
  border: 1px solid var(--border-light);
}
.counter-grid__item[data-bg=white] {
  background-color: white;
}
.counter-grid__item[data-bg=primary] {
  background-color: var(--bg-primary);
}
.counter-grid__item[data-bg=secondary] {
  background-color: var(--bg-secondary);
}
.counter-grid__item[data-bg=dark] {
  background-color: var(--bg-dark);
  color: white;
}
.counter-grid__item[data-bg=light] {
  background-color: var(--bg-light);
}
.counter-grid__item[data-bg=primary] .counter-grid__number, .counter-grid__item[data-bg=dark] .counter-grid__number {
  --title: white;
}
.counter-grid__item[data-bg=primary] .counter-grid__text, .counter-grid__item[data-bg=dark] .counter-grid__text {
  --text: white;
}
.counter-grid__icon {
  flex: 0 0 auto;
  width: 80px;
  height: auto;
  margin-block-end: 1rem;
}
.counter-grid__number {
  margin-block-end: 0.5rem;
  margin: 0 0 1rem 0;
  padding: 0;
  font-weight: 500;
  line-height: 1.3;
  color: var(--title);
}
.counter-grid__number {
  font-size: 32px;
}
@media screen and (min-width: 576px) {
  .counter-grid__number {
    font-size: calc(32px + 8 * ((100vw - 576px) / 704));
  }
}
@media screen and (min-width: 1280px) {
  .counter-grid__number {
    font-size: 40px;
  }
}
.counter-grid__text {
  font-size: 16px;
}

.teaser-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
}
@media (max-width: 1199.98px) {
  .teaser-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 767.98px) {
  .teaser-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.teaser-grid__item {
  cursor: pointer;
  position: relative;
  display: grid;
  grid-column: span 1;
  background-color: var(--bg-light);
  text-decoration: none;
}
.teaser-grid__item--big {
  grid-column: span 2;
}
@media (max-width: 767.98px) {
  .teaser-grid__item--big {
    grid-column: span 1;
  }
}
.teaser-grid__item:hover .teaser-grid__image img {
  scale: 1.15;
}
@media (hover: none) {
  .teaser-grid__item .teaser-grid__image img {
    scale: 1;
  }
}
.teaser-grid__image {
  position: relative;
  grid-column: 1/-1;
  grid-row: 1/-1;
  aspect-ratio: 3/2;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 5;
  overflow: clip;
}
.teaser-grid__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  -webkit-transition: scale 0.5s ease-in-out;
  -moz-transition: scale 0.5s ease-in-out;
  -o-transition: scale 0.5s ease-in-out;
  transition: scale 0.5s ease-in-out;
}
.teaser-grid__title {
  display: grid;
  align-items: flex-end;
  position: relative;
  grid-column: 1/-1;
  grid-row: 1/-1;
  font-size: 24px;
  --title: white;
  padding: 24px;
  margin: 0;
  z-index: 20;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.6), 0 2px 4px rgba(0, 0, 0, 0.4);
}

.testimonial {
  display: flex;
  margin-block-start: 64px;
}
@media (max-width: 991.98px) {
  .testimonial {
    margin-block-start: 48px;
  }
}
@media (max-width: 767.98px) {
  .testimonial {
    margin-block-start: 0;
    flex-direction: column;
  }
}
.testimonial__figure {
  flex-shrink: 0;
  align-self: baseline;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 100vh;
  width: 100%;
  height: auto;
  max-width: 240px;
  border: 8px solid var(--color-secondary);
  margin: 0;
  padding: 0;
  overflow: clip;
  scale: 1.2;
  transform-origin: left center;
}
@media (max-width: 767.98px) {
  .testimonial__figure {
    max-width: 200px;
    transform-origin: left top;
    scale: 1.1;
  }
}
.testimonial__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.testimonial__content {
  align-self: center;
  background-color: var(--bg-light);
  padding: 40px 40px 40px 80px;
}
@media (max-width: 767.98px) {
  .testimonial__content {
    padding: 48px 32px 32px 32px;
  }
}