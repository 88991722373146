  /* noto-sans-jp-regular - latin */
  @font-face {
       font-family: 'Noto Sans JP';
       font-style: normal;
       font-weight: 400;
       src: url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.eot');
       /* IE9 Compat Modes */
       src: local(''),
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.woff2') format('woff2'),
            /* Super Modern Browsers */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.woff') format('woff'),
            /* Modern Browsers */
          //   url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.ttf') format('truetype'),
            /* Safari, Android, iOS */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-regular.svg#NotoSansJP') format('svg');
       /* Legacy iOS */
  }

  /* noto-sans-jp-500 - latin */
  @font-face {
       font-family: 'Noto Sans JP';
       font-style: normal;
       font-weight: 500;
       src: url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.eot');
       /* IE9 Compat Modes */
       src: local(''),
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.woff2') format('woff2'),
            /* Super Modern Browsers */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.woff') format('woff'),
            /* Modern Browsers */
          //   url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.ttf') format('truetype'),
            /* Safari, Android, iOS */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-500.svg#NotoSansJP') format('svg');
       /* Legacy iOS */
  }

  /* noto-sans-jp-700 - latin */
  @font-face {
       font-family: 'Noto Sans JP';
       font-style: normal;
       font-weight: 700;
       src: url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.eot');
       /* IE9 Compat Modes */
       src: local(''),
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.woff2') format('woff2'),
            /* Super Modern Browsers */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.woff') format('woff'),
            /* Modern Browsers */
          //   url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.ttf') format('truetype'),
            /* Safari, Android, iOS */
            url('../fonts/noto-sans-jp/noto-sans-jp-v42-latin-700.svg#NotoSansJP') format('svg');
       /* Legacy iOS */
  }




  /* Genos GFG */
  /* genos-gfg - regular*/
@font-face {
     font-family: 'GenosGFG';
     font-style: normal;
     font-weight: 400;
     src: url('../fonts/genos-gfg/GenosGFG-Regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/genos-gfg/GenosGFG-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/genos-gfg/GenosGFG-Regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-Regular.woff') format('woff'), /* Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/genos-gfg/GenosGFG-Regular.svg') format('svg'); /* Legacy iOS */
   }
/* genos-gfg - regular italic*/
@font-face {
     font-family: 'GenosGFG';
     font-style: italic;
     font-weight: 400;
     src: url('../fonts/genos-gfg/GenosGFG-RegularItalic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/genos-gfg/GenosGFG-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/genos-gfg/GenosGFG-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-RegularItalic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-RegularItalic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/genos-gfg/GenosGFG-RegularItalic.svg') format('svg'); /* Legacy iOS */
   }

/* genos-gfg - condensed*/
@font-face {
     font-family: 'GenosGFG';
     font-style: normal;
     font-weight: 500;
     src: url('../fonts/genos-gfg/GenosGFG-Condensed.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/genos-gfg/GenosGFG-Condensed.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/genos-gfg/GenosGFG-Condensed.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-Condensed.woff') format('woff'), /* Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-Condensed.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/genos-gfg/GenosGFG-Condensed.svg') format('svg'); /* Legacy iOS */
   }
/* genos-gfg - bold */
@font-face {
     font-family: 'GenosGFG';
     font-style: normal;
     font-weight: 600;
     src: url('../fonts/genos-gfg/GenosGFG-Bold.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/genos-gfg/GenosGFG-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/genos-gfg/GenosGFG-Bold.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-Bold.woff') format('woff'), /* Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/genos-gfg/GenosGFG-Bold.svg') format('svg'); /* Legacy iOS */
   }
/* genos-gfg - bold italic */
@font-face {
     font-family: 'GenosGFG';
     font-style: italic;
     font-weight: 600;
     src: url('../fonts/genos-gfg/GenosGFG-BoldItalic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/genos-gfg/GenosGFG-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/genos-gfg/GenosGFG-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-BoldItalic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/genos-gfg/GenosGFG-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/genos-gfg/GenosGFG-BoldItalic.svg') format('svg'); /* Legacy iOS */
   }