.btn {
    display: inline-block;
    padding-inline: 2rem;
    padding-block: .75rem;
    color: white;
    @include fluid-type($min_width, $max_width, 16px, 20px);
    font-weight: 600;
    background-color: var(--btn-primary);
    border-radius: 50vw;
    text-decoration: none;
    @include effect();

    &:hover {
        background-color: var(--btn-primary-hover);       
    }

    &--secondary {
        color: #ffffff;
        background-color: var(--btn-secondary);
        
        &:hover {     
            background-color: var(--btn-secondary-hover);       
        }
    }

    &--dark {
        background-color: var(--btn-dark);
        
        &:hover {
            background-color: var(--btn-dark-hover);       
        }
    }

    &--neutral {
        color: var(--title);
        background-color: var(--btn-neutral);
        
        &:hover {
            color: var(--title);
            background-color: var(--btn-neutral-hover);       
        }
    }
}

.btn-bar {
    margin-block-start: 3rem;

    &--mb-4 {
        margin-block-end: 4rem;
    }
}