.logo, .logo-bg {
    position: fixed;
    inset: 50px auto auto 40px;
    display: inline-block;
    z-index: 999;
    @include effect(all, .5s, ease-in-out);
    transition-delay: .25s;

    @include media-breakpoint-down(lg) {
        inset: 20px auto auto 20px;
    }

    @include media-breakpoint-down(sm) {
        inset: 30px auto auto 20px;
    }    

    &.hide {
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
    }

    img {
        width: 240px;
        height: auto;

        @include media-breakpoint-down(lg) {
            width: 180px;
        }

        @include media-breakpoint-down(sm) {
            width: 140px;
        }
    }
}

.logo-bg {
    padding: 1.5rem 1.5rem 1.0rem 1.5rem;
    background-color: #ffffff;
    border-radius: 0 0 7px 7px;
    inset: 0 auto auto 40px;
    @include media-breakpoint-down(sm) {
        inset: 0 auto auto 20px;
        padding: 0.5rem 0.5rem 0rem 0.5rem;
    }
}