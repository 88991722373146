:root {
	--toggle-btn: var(--color-primary);
	--toggle-icon: white;
	--toggle-btn-current: var(--color-secondary);
	--toggle-icon-current: white;
}

.menu-toggle {
	cursor: pointer;
	position: fixed;
	inset: 40px 40px auto auto;
	width: 80px;
	height: 80px;
	background-color: var(--toggle-btn);
	border-radius: 50%;
	box-shadow: 0 10px 30px rgba(black, 0.15);
	border: 0;
	z-index: 2000;

	@include media-breakpoint-down(sm) {
		inset: 20px 20px auto auto;
		width: 40px;
		height: 40px;
	}

	span {
		position: absolute;
		top: calc(50% - 1px);
		left: 50%;
		width: 30px;
		height: 4px;
		border-radius: 2px;
		background-color: var(--toggle-icon);

		&:nth-child(1) {
			transform: translate(-50%, -9px);
		}

		&:nth-child(2) {
			transform: translate(-50%, 0);
		}

		&:nth-child(3) {
			transform: translate(-50%, 9px);
		}

		@include media-breakpoint-down(sm) {
			width: 17px;
			height: 2px;

			&:nth-child(1) {
				transform: translate(-50%, -6px);
			}

			&:nth-child(2) {
				transform: translate(-50%, 0);
			}

			&:nth-child(3) {
				transform: translate(-50%, 6px);
			}
		}
	}
}

.menu {
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0 auto 0 120%;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	background-color: white;
	box-shadow: 0 0 100px rgba(black, 0.1);
	z-index: 1000;
	overflow: auto;
}

.menu-nav {
	padding-inline: 6rem;
	padding-block: 120px;
	width: 100%;
	max-width: 800px;
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 3em;

	@include media-breakpoint-down(xl) {
		// grid-template-columns: repeat(2, minmax(0, 1fr));
		padding-inline: 4rem;
		gap: 3em;
	}

	@include media-breakpoint-down(md) {
		padding-block: 60px;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		padding-inline: 2rem;
		gap: 2em;
	}

	&__title {
		position: relative;
		display: block;
		margin-block-end: 0.75em;
		padding-block-end: .25em;
		@include fluid-type($min_width, $max_width, 20px, 24px);
		font-weight: 600;
		line-height: 1.6;
		color: var(--color-tertiary);

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: var(--color-tertiary);
		}

		&[data-color="deafult"] {
			color: var(--color-secondary);

			&::after {
				background-color: var(--color-secondary);
			}
		}
	}

	&__pic {
		flex-shrink: 0;
		width: 100%;
		max-width: 80px;
		// margin-block-end: 1em;
		// margin-inline-end: 1em;
		aspect-ratio: 1/1;
		
		@include media-breakpoint-down(md) {
			max-width: 60px;
		}
	}

	&__item {
		position: relative;
		display: block;
		// align-items: flex-end;
		left: 4rem;
		opacity: 0;

		&:not(:last-child) {
			margin-block-end: .25em;
			
			@include media-breakpoint-down(md) {
				margin-block-end: .25em;
			}
		}
	}

	// &__pdf {
	// 	display: inline-block;
	// 	padding: .25rem 1rem;
	// 	@include fluid-type($min_width, $max_width, 14px, 18px);
	// 	color: white;
	// 	background-color: var(--btn-primary);
	// 	border-radius: 50vw;
	// 	text-decoration: none;
	// 	@include effect();

	// 	&:hover {
	// 		background-color: var(--btn-primary-hover);
	// 	}
	// }

	&__link {
		position: relative;
		display: inline-block;
		@include fluid-type($min_width, $max_width, 16px, 18px);
		padding-inline-start: 1.5em;
		font-weight: 400;
		line-height: 1.4;
		color: var(--color-tertiary);
		text-decoration: none;
		@include effect();

		&::after {
			content: "";
			position: absolute;
			top: .2em;
			left: 0;
			width: 1em;
			height: 1em;
			border-radius: 50vw;
			background-color: var(--color-tertiary);
			mask-image: url("../img/icon/ic_pfeil-rechts_24.svg");
			mask-position: center;
			mask-size: cover;
		}

		&:hover {
			color: var(--color-primary);
			
			&::after {
				background-color: var(--color-primary);
			}
		}

		&[aria-current="page"] {
			color: var(--color-primary);

			&::after {
				background-color: var(--color-primary);
			}

			&[onclick] {
				color: inherit;
			}
		}
	}
}

.menu-pdf {
	// position: absolute;
	// inset: auto 40px 40px auto;
	display: inline-block;
	padding: .25rem 1rem;
	@include fluid-type($min_width, $max_width, 14px, 16px);
	color: white;
	font-weight: 600;
	background-color: var(--btn-primary);
	border-radius: 50vw;
	text-decoration: none;
	@include effect();

	&:hover {
		background-color: var(--btn-primary-hover);
	}

	@include media-breakpoint-down(sm) {
		inset: auto 20px 20px auto;
	}
}

.menu-logo {
	position: absolute;
	inset: 40px auto auto 40px;
	margin-right: 2rem;

	img {
		width: 240px;
		height: auto;
	}

	@include media-breakpoint-down(sm) {
		inset: 20px auto auto 20px;

		img {
			width: 200px;
		}
	}
}