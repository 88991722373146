.hero {
    position: relative;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background-color: var(--bg-dark);
}

.hero-image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
}

.hero-title {
    margin-block: 55vh 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;

    // @include media-breakpoint-down(sm) {
    //   margin-block: 8rem 0;
    // }

    &[data-shadow="text"],
    [data-shadow="text"] {
        text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);

        @include media-breakpoint-down(lg) {
            text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5), 0 5px 20px rgba(0, 0, 0, 0.5), 0 3px 7px rgba(0, 0, 0, 0.5);
        }
    }

    .smaller {
        font-size: 48px;
        display: inline;
        line-height: 60px;
        background-color: white;
        text-shadow: none;
        color: var(--color-tertiary);
        margin-block: 0.15rem;

        &.orange {
            background-color: var(--color-secondary);
            color: white;
        }

        @include media-breakpoint-down(sm) {
            font-size: 26px;
            line-height: 34px;
        }

    }
}

.hero-detail {
    position: relative;
    background-color: var(--bg-dark);
    width: 100%;
    height: 100%;
    max-height: 800px;
    aspect-ratio: 3/2;

    &[data-size="full"] {
        height: 100vh;
        max-height: initial;
        aspect-ratio: initial;

        @include media-breakpoint-down(lg) {
            height: initial;
            aspect-ratio: 5/3;
        }
    }

    .image {
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.hero-title-year {
    display: inline-block;
    @include fluid-type($min_width, $max_width, 24px, 48px);
    color: white;
    font-weight: 600;
}

.hero-title-box {
    background-color: var(--bg-dark);
    height: 100%;
    width: auto;
    display: inline-block;
    padding: 1em 2em;
    
    @include media-breakpoint-down(sm) {
        padding: 1em;
    }

    h1 {
        margin-block-end: initial;
        display: inline-block;
        position: relative;
        @include fluid-type($min_width, $max_width, 32px, 80px);
        color: white;
        z-index: 10;
        font-weight: 600;
    }

    p {
        @include fluid-type($min_width, $max_width, 20px, 32px);
        color: white;
        font-weight: 400;
    }
}