.video {
  width: 100%;
  aspect-ratio: 16/9;

  iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.video-cta {
  position: relative;
  display: block;

  &:hover {
    &::before {
      background-color: var(--btn-secondary-hover);
    }

  }

  &::before {
    content: "";
    position: absolute;
    inset: 50% auto auto 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--btn-secondary);
    background-image: url("../img/icon/icon-play.svg");
    background-position: left 55% top 50%;
    background-size: 33%;
    background-repeat: no-repeat;
    translate: -50% -50%;
    z-index: 5;
    @include effect();

    @include media-breakpoint-down(md) {
      width: 60px;
      height: 60px;
    }
  }
}