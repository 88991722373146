$primary: #0066b3;
$secondary: #ff6600;
$tertiary: #012f63;

$primary-1: #327DC8;
$primary-2: #649BD7;
$primary-3: #96BEE6;
$primary-4: #ECEFF1;
$tertiary-1: #455a64;
$tertiary-2: #78909c;
$tertiary-3: #b0bec5;
$tertiary-4: #eceff1;

:root {
	--color-primary: rgb(0,102, 179); //#0066b3
	--color-secondary: rgb(255, 102, 0); //#FF6600
	--color-tertiary: rgb(0, 45, 103); //#002d67
	--color-tertiary-1: rgb(69, 90, 100); //#455A64
	--color-tertiary-2: rgb(120, 144, 156); //#78909C
	--color-tertiary-3: rgb(176, 190, 197); //#B0BEC5
	--color-tertiary-4: rgb(236, 239, 241); //#ECEFF1
	--color-text:rgb(51, 51, 51); //#333333

	--color-primary-1: rgb(50, 125, 200); //#327DC8
	--color-primary-2: rgb(100, 155, 215); //#649BD7
	--color-primary-3: rgb(150, 190, 230); //#96BEE6
	--color-primary-4: rgb(225, 235, 245); //#E1EBF5

	--btn-primary: #{$primary};
	--btn-primary-hover: #{darken($primary, 5%)};
	--btn-secondary: #{$secondary};
	--btn-secondary-hover: #{darken($secondary, 14%)};
    --btn-dark: #{$tertiary};
	--btn-dark-hover: #{lighten($tertiary, 14%)};
	--btn-neutral: #{$tertiary-3};
	--btn-neutral-hover: #{darken($tertiary-3, 10%)};
    --btn-light: #{$primary-4};
	--btn-light-hover: #{darken($primary-4, 10%)};


	--title: var(--color-tertiary);
	--text: var(--color-text);
	--meta: var(--color-primary-1);
	--link: var(--color-secondary);

	--bg-primary: var(--color-primary);
	--bg-secondary: var(--color-secondary);
	--bg-dark: var(--color-tertiary);
	--bg-light: var(--color-tertiary-4);
	--bg-gradient-rgb: 0, 45, 103; //#002d67, muss direkt reingeschrieben werden

	--border: var(--color-tertiary-3);
	--border-light: var(--color-tertiary-4);

	--font-title: "GenosGFG", Arial, sans-serif;
	--font-main: "GenosGFG", Arial, sans-serif;
	--font-size: 20px;
	--font-weight: 400;
	--line-height: 1.6;
}

//Transition general
@mixin effect($class: all, $time: 150ms, $easing: ease-in-out) {
	-webkit-transition: $class $time $easing;
	-moz-transition: $class $time $easing;
	-o-transition: $class $time $easing;
	transition: $class $time $easing;
}

//Hyphenate
@mixin hyphenate() {
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

//Fluid Font
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

$min_width: 576px;
$max_width: 1280px;
$min_font: 16px;
$max_font: 20px;
